import { CheckInOrderEntityTimesEntity, Order } from '@/common/utilities/types/Order.type';
import { Session } from '@/common/utilities/types/Session.type';
import { uuid } from '@/proxies/uuid.proxy';
import { localStorageService } from '@/services/localStorage.service';
import { isBefore, startOfDay } from 'date-fns';
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules';
import { AdultParticipant } from '../common/utilities/types/AdultParticipant.type';
import { Client, KioskDefaultLanguageEntity, LanguagesEntity } from '../common/utilities/types/client.type';
import { MinorParticipant } from '../common/utilities/types/MinorParticipant.type';
import { apiService } from './../services/api.service';
import store from './index';

@Module({ generateMutationSetters: true })
class KioskModule extends VuexModule {
	private _lang: Map<string, string> = new Map();
	private _loading = false;
	private _languageEntity: LanguagesEntity | null = null;
	public _session: Session = this.newSession();
	public _client: Client | null = null;
	private _clientCode: string | null = null;
	private _langMap: Map<string, number> = new Map();
	private _onSite = false;
	private _unassignedWaiver = false;

	// #REGION GETTERS

	get langMap() {
		return this._lang;
	}

	get isLoading() {
		return this._loading;
	}

	get unassignedWaiver() {
		return this._unassignedWaiver;
	}

	get defaultClientCountryKey() {
		return this._client?.CountryKey;
	}

	get clientLocationKey() {
		return this._client?.ClientLocationKey;
	}

	get clientLocationName() {
		return this._client?.LocationName;
	}
	get languageKey() {
		return this._session.languageKey;
	}
	get clientLogoUrl() {
		if (!this._client) return '';
		return `${this._client.PhotoDomain}v${this._client.DarkLogoVersion ?? ''}${this._client.PhotoFolder}/darklogo`;
	}

	get client() {
		return this._client;
	}

	get collectSkiRentalInfo() {
		return this._client?.CollectDINInfo ?? false;
	}

	get skiRentalFieldResponses() {
		if (!this.collectSkiRentalInfo) return null;
		return {
			experience: this._client!.DIN_ExperienceLevels!.map((f) => {
				return {
					key: f.ExperienceLevelHistoryKey,
					desc: f.ExperienceLevelDesc,
				};
			}),
			heights: this._client!.DIN_Heights!.map((f) => {
				return {
					key: f.HeightHistoryKey,
					desc: f.HeightDesc,
				};
			}),
			shoes: this._client!.DIN_ShoeSizes?.map((f) => {
				return {
					key: f.ShoeSizeHistoryKey,
					desc: f.ShoeSizeDesc,
				};
			}),
			weights: this._client!.DIN_Weights!.map((f) => {
				return {
					key: f.WeightHistoryKey,
					desc: f.WeightDesc,
				};
			}),
		};
	}

	get clientName() {
		return this._client;
	}

	get orderKey(): string {
		return this._session.orderKey;
	}

	get minorParticipants(): MinorParticipant[] {
		return this._session.consenter.consentMinors;
	}

	get order(): Order | null {
		return this._session.order;
	}

	get consenter(): AdultParticipant {
		return this._session.consenter;
	}

	get session(): Session {
		return this._session;
	}

	get clientCode() {
		return this._clientCode;
	}

	get language(): LanguagesEntity | null {
		return this._languageEntity;
	}

	get minorCustomFields() {
		if (this._client?.ConsentForm.MinorCustomFields)
			return this._client?.ConsentForm.MinorCustomFields.map((cf) => {
				return { ...cf, OrderKey: this.orderKey };
			});
		return [];
	}

	get adultCustomFields() {
		return this._client?.ConsentForm.CustomFields ?? [];
	}

	get activityParticipants() {
		return this._session.activityParticipants;
	}

	get isEditing() {
		return this._session.isEditing;
	}

	get adultAge() {
		return this._client?.AdultAge ?? 18;
	}

	get expiredOrder() {
		const bookings = this.order?.CheckInOrderEntities.reduce((e: CheckInOrderEntityTimesEntity[][], c) => {
			e.push(c.CheckInOrderEntityTimes);
			return e;
		}, []).flat();

		if (!bookings || !bookings.length || (bookings.length && bookings.some((b) => b!.AppointmentTypeKey == 3))) return false;
		return bookings.every((b) => isBefore(new Date(b!.AppointmentDate as string), startOfDay(new Date())));
	}

	// #ENDREGION

	initSession(): Session {
		console.log('[Initializing Kiosk]');

		// this._client = localStorageService.get('client');
		// this._clientCode = localStorageService.get('clientCode');

		this._langMap.set('en', 1);
		this._langMap.set('ja', 2);
		this._langMap.set('zh', 3);
		this._langMap.set('ko', 5);
		this._langMap.set('fr', 6);
		this._langMap.set('es', 7);
		this._langMap.set('de', 8);
		this._langMap.set('pt-BR', 9);

		const session = localStorageService.get('session') as Session;
		if (session) {
			this._loading = true;
			console.log(`Restoring Session...`, session);
			this._session = session;
			if (!session.isEditing) this._session.formAgreements = [];
		}
		this._loading = false;
		return this._session;
	}

	@Action async toggleActivityParticipant(p: { activityId: string; participantId: string | string[] }) {
		let participants = this._session.activityParticipants[p.activityId] ?? [];

		const idArr = Array.isArray(p.participantId) ? p.participantId : [p.participantId]; // multiple
		idArr.forEach((id) => {
			if (participants.includes(id)) participants = participants.filter((pid) => pid != id);
			else participants.push(id);
		});

		if (!participants.length) delete this._session.activityParticipants[p.activityId];
		else this._session.activityParticipants[p.activityId] = participants;

		this.saveSession();
	}

	/**
	 * Assigns participants to activity (as opposed to toggling participants)
	 * @param p
	 */
	@Action async assignActivityParticipants(p: { activityId: string; participantId: string | string[] }) {
		const participants = new Set(this._session.activityParticipants[p.activityId] ?? []);

		if (Array.isArray(p.participantId)) {
			(p.participantId as string[]).forEach((id) => participants.add(id));
		} else {
			participants.add(p.participantId);
		}

		this._session.activityParticipants[p.activityId] = Array.from(participants);
		this.saveSession();
	}

	@Action async searchWaiver(waiver: { firstName: string; lastName: string; dateOfBirth: string; orderKey: string }) {
		return await apiService.get('searchWaiver', {
			...waiver,
			clientLocationKey: this.clientLocationKey,
		});
	}
	@Action async getOrderData(orderKey: string) {
		return await apiService
			.get('getCheckinOrders', {
				clientLocationKey: this.clientLocationKey,
				orderKey: orderKey,
				showAll: true,
			})
			.then((orders: Order[]) => {
				if (Array.isArray(orders) && orders.length) {
					const order = orders.find((o) => o.OrderKey.toLowerCase() == orderKey.toLowerCase());
					if (order) {
						console.log('[Order]', order);
						this._session.orderKey = orderKey;
						this.updateOrder(order);
						return true;
					}
					return false;
				}
			})
			.catch(() => {
				console.error(`Could not lookup order ${orderKey}.`);
				return false;
			});
	}

	@Action async submitWaiver(): Promise<boolean> {
		const payload = {
			City: this.consenter?.city,
			CountryKey: this.consenter?.countryKey,
			State: this.consenter?.state,
			ZipCode: this.consenter?.zipCode,
			DateOfBirth: this.consenter?.dateOfBirth.formatted,
			Email: this.consenter?.email,
			Guid: this.consenter?.guid,
			LanguageKey: this._session.languageKey,
			FirstName: this.consenter?.firstName,
			LastName: this.consenter?.lastName,
			ClientLocationKey: this.clientLocationKey,
			EmailOptIn: this.consenter?.emailOptIn,
			IsParticipating: this.consenter?.isParticipating,
			DIN_ExperienceLevelHistoryKey: this.collectSkiRentalInfo ? this.consenter?.skiExperienceKey : null,
			DIN_HeightHistoryKey: this.collectSkiRentalInfo ? this.consenter?.skiHeightKey : null,
			DIN_WeightHistoryKey: this.collectSkiRentalInfo ? this.consenter?.skiWeightKey : null,
			DIN_ShoeSizeHistoryKey: this.collectSkiRentalInfo ? this.consenter?.skiShoeSizeKey : null,
			ConsentMinors: this.consenter?.consentMinors?.map((m) => {
				return {
					CanDelete: m.canDelete,
					CustomFields: m.customFields ?? [],
					Email: m.email,
					FirstName: m.firstName,
					LastName: m.lastName,
					dd: m.dateOfBirth.day,
					mm: m.dateOfBirth.month,
					yy: m.dateOfBirth.year,
					Guid: m.guid,
					DateOfBirth: m.dateOfBirth.formatted,
					ConsentKey: m.consentKey ?? 0,
					ConsentMinorGuid: m.consentMinorGuid ?? '',
					ConsentMinorKey: m.consentMinorKey ?? 0,
					DIN_ExperienceLevelHistoryKey: this.collectSkiRentalInfo ? m.skiExperienceKey : null,
					DIN_HeightHistoryKey: this.collectSkiRentalInfo ? m.skiHeightKey : null,
					DIN_WeightHistoryKey: this.collectSkiRentalInfo ? m.skiWeightKey : null,
					DIN_ShoeSizeHistoryKey: this.collectSkiRentalInfo ? m.skiShoeSizeKey : null,
				};
			}),
			UserConsentOrderEntityTimes: Object.keys(this.activityParticipants).map((activityId) => {
				const participants = this.activityParticipants[activityId];
				return {
					OrderEntityTimeKey: activityId,
					UserConsentOrderEntityTimeKey: 0,
					Consentees: {
						Consentor: {
							Guid: this.consenter.guid,
							IsParticipating: participants.includes(this.consenter.guid),
							ConsentKey: this.consenter?.consentKey,
							ConsentGuid: this.consenter?.consentGuid,
						},
						ConsentMinors: participants
							.filter((id) => id != this.consenter.guid)
							.map((m) => {
								return { Guid: m };
							}),
					},
				};
			}),
			ConsentKey: this.consenter?.consentKey,
			ConsentGuid: this.consenter?.consentGuid,
			CustomFields: this.consenter.customFields.map((cf) => {
				return {
					...cf,
					OderKey: this.orderKey,
				};
			}),
			Onsite: this._onSite,
			SignatureBase64: this._session.signature,
			ConsentForms: this._session.formAgreements.map((form) => {
				return {
					ConsentFormKey: form.formId,
					ConsentFormQuestions: form.questions.map((q) => {
						return { ConsentFormQuestionKey: q };
					}),
				};
			}),
		};
		console.log('[Submit Consent]', payload);
		return await apiService.post('saveUserConsent', payload).then((res) => {
			return !!res;
		});
	}

	@Action updateAgreements(agreement: { formKey: number; questionKey: number }) {
		let formIdx = this._session.formAgreements.findIndex((f) => f.formId == agreement.formKey);

		if (formIdx == -1) {
			this._session.formAgreements.push({ formId: agreement.formKey, questions: [] });
			formIdx = this._session.formAgreements.length - 1;
		}

		const questionSet = new Set(this._session.formAgreements[formIdx].questions); // remove duplicates
		questionSet.add(agreement.questionKey);

		this._session.formAgreements[formIdx].questions = [...questionSet];
		this.saveSession();
	}

	@Action updateSignature(signature: string) {
		this._session.signature = signature;
		this.saveSession();
	}

	@Action saveSession(s?: Session) {
		if (s) this._session = s;
		localStorageService.set('session', this._session);
	}

	@Action updateAdult(a: AdultParticipant) {
		this._session.consenter = a;
		this.saveSession();
	}

	@Action updateOrder(o: Order) {
		this._session.order = o;
		this.saveSession();
	}

	@Action async updateClient(c: Client) {
		this._client = c;
		// Most likely this.client was undefined at original session creation, so update emailOptIn here once the value is actually set.
		// This is also the case for custom questions but that is handled in adult.view.vue:196. ~Caleb
		this.consenter.emailOptIn = !this.client?.RequireEmailOptIn;
		localStorageService.set('client', this._client);
	}

	@Action async updateClientCode(code: string) {
		this._clientCode = code;
		localStorageService.set('clientCode', this._clientCode);
	}

	@Action async startSession() {
		this._session.started = new Date().toString();
	}

	@Action async addMinorParticipant(p: MinorParticipant) {
		const existingEntry = this._session.consenter?.consentMinors?.findIndex((m) => m.guid == p.guid) ?? -1;
		if (existingEntry > -1) this._session.consenter!.consentMinors![existingEntry] = p;
		else this._session.consenter?.consentMinors?.push(p);
		this.saveSession();
	}

	@Action async removeMinorParticipant(guid: string) {
		const minor = this._session.consenter.consentMinors.find((m) => m.guid == guid);
		if (minor?.consentMinorKey) {
			minor.isDeleted = true;
		} else {
			this._session.consenter.consentMinors = this._session.consenter.consentMinors.filter((m) => m.guid != guid);
		}
		this.saveSession();
	}

	@Action async clearMinorParticipants() {
		this._session.consenter.consentMinors = [];
		this.saveSession();
	}

	@Action async setAdultParticipantStatus(s: boolean) {
		this._session.consenter!.isParticipating = s;
		this.saveSession();
	}

	@Action async updateLastPage(pageName: string) {
		this._session.lastPage = pageName;
		this.saveSession();
	}

	@Action async updateOrderKey(key: string) {
		this._session.orderKey = key;
		this.saveSession();
	}

	@Action toggleEdit() {
		this.session.isEditing = true;
		this.saveSession();
	}

	@Action setOnSite(s: boolean) {
		this._onSite = s;
	}

	@Action setUnassigned(assigned: boolean) {
		this._unassignedWaiver = assigned;
	}

	@Action async getClientLocationData(clientCode: string) {
		this._loading = true;
		return await apiService
			// .get(`getClientLocation`, {
			// 	locationCode: clientCode,
			// })
			// .externalGet(`${(window as any).config.ApiUrl}/v1/clientlocation/getClientLocation`, {
			// 	locationCode: clientCode,
			// })
			.get('getClientLocation', { locationCode: clientCode })
			.then((c: Client) => {
				if (c.ClientLocationKey) {
					this.updateClient(c);
					console.log('Languages:', this.client?.Languages);
					return c.ClientLocationKey;
				}
				return null;
			})
			.catch((e) => {
				console.error('Error getting client location data', e);
				return null;
			})
			.finally(() => (this._loading = false));
	}

	@Mutation addLanguageEntry(entry: KioskDefaultLanguageEntity) {
		this._lang.set(entry.Name, entry.Value);
	}

	langLookup(key: string): number {
		return this._langMap.get(key) ?? 1;
	}

	resetSession(): Session {
		console.info('Resetting session...');
		localStorageService.remove('session');
		this._session = this.newSession();
		return this._session;
	}

	newSession(): Session {
		return {
			type: 'Consent',
			orderKey: '',
			consenter: {
				consentKey: 0,
				consentGuid: '',
				consentMinors: [],
				customFields: this.adultCustomFields,
				firstName: '',
				lastName: '',
				isParticipating: true,
				email: '',
				emailOptIn: !this.client?.RequireEmailOptIn,
				zipCode: '',
				city: '',
				state: '',
				countryKey: 196,
				dateOfBirth: {
					day: null,
					month: null,
					year: null,
				},
				languageKey: 1,
				guid: uuid.v4(),
				skiExperienceKey: undefined,
				skiHeightKey: undefined,
				skiShoeSizeKey: undefined,
				skiWeightKey: undefined,
			},
			started: '',
			order: null,
			lastPage: null,
			languageKey: 1,
			formAgreements: [],
			signature: '',
			onsite: this._onSite,
			activityParticipants: {},
			isEditing: false,
			editActivities: [],
		};
	}
}

export const kioskModule = new KioskModule({ store, name: 'kiosk' });
