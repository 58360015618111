import { Availability } from '@/common/models/Availability.model';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';
import { OrderEntityTimeDiscount } from '@/common/models/OrderEntityTimeDiscount.model';
import { Time } from '@/common/models/Time.model';
import { apiService } from '@/services/api.service';
import { format, isSameDay, startOfDay } from 'date-fns';
import { EntityHierarchyRate } from '@/common/models/EntityHierarchyRate.model';
import { FormModel } from '@/common/models/Form.model';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { DayOfTheMonth } from '@/common/models/DayOfTheMonth.model';
import { OrderEntityTimeAddOn } from '@/common/models/OrderEntityTime.model';
import moment from 'moment';
import { clientModule } from '@/modules/client/client.vuex-module';

export default class OrderActivity {
	private _activity: EntityHierarchy;
	private _availableTimes: Map<number, Time> = new Map();
	private _selectedTimes: Map<number, Time> = new Map();
	private _lastTimeString: string | null = null;

	private _isLargeGroup: boolean;
	private _appointmentDate: Date | null = null;
	private _isLoading = false;
	private _monthAvailability: Map<string, DayOfTheMonth[]> = new Map();
	private _pendingTime: Time | null = null;
	getTimesLoading = false;
	constructor(activity: EntityHierarchy) {
		console.log('[Order Activity]', activity);
		this._activity = activity;
		this._isLargeGroup = activity.SelectedTimes.length >= 2;
		if (activity.AppointmentDate) this._appointmentDate = new Date(activity.AppointmentDate);
		else console.log('*** NO APPOINTMENT DATE ***');
		if (activity.SelectedTimes.length) {
			activity.SelectedTimes.forEach((time) => {
				this._availableTimes.set(time.Value, time);
				this._selectedTimes.set(time.Value, time);
				// console.log('*** SETTING SELECTED TIME', time);
			});
		}
	}

	//#region  ACCESSORS
	get activity(): EntityHierarchy {
		return this._activity;
	}

	get activitySummary() {
		if (!(this._appointmentDate && this.selectedTimes.length)) return null;

		if (this._isLargeGroup) {
			return {
				date: format(this._appointmentDate!, 'EEEE MMM d'),
				times: this.selectedTimes.map((t) => {
					return {
						name: t.DisplayName,
						rates: this.buildRateString(t.Rates),
					};
				}),
			};
		}

		return {
			date: format(this._appointmentDate!, 'EEE, MMM d'),
			times: [
				{
					name: this.selectedTimes[0].DisplayName,
					rates: this.buildRateString(this.rates),
				},
			],
		};
	}

	get appliedDiscounts() {
		return this.selectedTimes.reduce((p: OrderEntityTimeDiscount[], c) => {
			if (c.Discounts.length) p.push(...c.Discounts);
			return p;
		}, []);
	}

	get availableTimes() {
		return Array.from(this._availableTimes.values());
	}

	get canSaveActivity() {
		return this._selectedTimes.size && this.rateCount;
	}

	get isSaved(): boolean {
		return orderModule.savedActivityKeys.has(this._activity.EntityHierarchyKey);
	}

	get entityHierarchyKey() {
		return this._activity.EntityHierarchyKey;
	}

	get clientLocationKey() {
		return this._activity.ClientLocationKey;
	}

	get isLargeGroup() {
		return this._isLargeGroup;
	}
	get hasRates() {
		return this.rateCount > 0;
	}
	get isPrivateTour() {
		return this._activity.IsPrivateTour;
	}

	get key() {
		return this._activity.EntityHierarchyKey;
	}

	get isLoading() {
		return this._isLoading;
	}

	get maxPeople() {
		return this._activity.MaxPeople;
	}
	get capacityType() {
		return this._activity.CapacityTypeKey;
	}

	get maxParticipants() {
		return this._activity.MaxParticipants;
	}
	set maxParticipants(n: number | null) {
		this._activity.MaxParticipants = n;
	}
	get addons(): OrderEntityTimeAddOn[] {
		return this._activity.AddOns;
	}
	set addons(newValue: OrderEntityTimeAddOn[]) {
		this._activity.AddOns = newValue;
	}
	get orderAddons(): OrderEntityTimeAddOn[] {
		return this._activity.AddOns.filter((a) => {
			// console.log(a.AddOnName, a.OrderMerchandiseKey, a.OrderPhotoPackageKey);
			// console.log(a.AddOnName, !!a.OrderMerchandiseKey || (!!a.OrderPhotoPackageKey && a.OrderPhotoPackageKey != '00000000-0000-0000-0000-000000000000'));
			return !!a.OrderMerchandiseKey;
		});
	}
	get photoAddOnInternalOnly(): boolean {
		return this._activity.PhotoAddOnInternalOnly;
	}

	get model(): FormModel {
		return {
			AppointmentDate: this._appointmentDate!,
			SelectedTimes: this.selectedTimes,
			OrderEntityKey: this._activity.OrderEntityKey,
			EntityHierarchyKey: this._activity.EntityHierarchyKey,
			OrderKey: this._activity.OrderKey,
			OrderPackageKey: this._activity.OrderPackageKey,
			PackageKey: this._activity.PackageKey,
			ClientLocationKey: this._activity.ClientLocationKey,
			Partner_ClientLocationKey: clientModule.clientLocationKey,
			NeedTransportation: this._activity.NeedTransportation,
			HotelName: this._activity.HotelName,
			HotelLatitude: this._activity.HotelLatitude,
			HotelLongitude: this._activity.HotelLongitude,
			TaxExempt: this._activity.TaxExempt,
			Discounts: [],
			PickupLocation: null,
			Rates: this.isLargeGroup
				? this.rates.map((rate) => {
						return {
							...rate,
							MinParticipants: 0,
						};
				  })
				: this.rates,
			AddOns: this.activity.AddOns,
			IsPrivateTour: this._activity.IsPrivateTour,
			PreviousPrivateTourState: false,
			CustomFields: this._activity.CustomFields,
			AppointmentTypeKey: this._activity.AppointmentTypeKey,
			HiddenActivities: this._activity.HiddenActivities,
		};
	}

	get monthAvailability() {
		return this._monthAvailability;
	}

	get name() {
		return this._activity.Name;
	}

	get orderEntityKey() {
		return this._activity.OrderEntityKey;
	}

	get overview() {
		return {
			difficulty: this._activity.Difficulty ?? null,
			duration: this._activity.Duration ?? null,
			ageMin: this._activity.AgeMinimum ?? null,
		};
	}

	get rateCount() {
		if (this._isLargeGroup) return this.selectedTimes.reduce((p, c) => (p += c.Rates.reduce((q, d) => (q += d.Participants), 0)), 0);

		return this._activity.Rates.reduce((p, c) => (p += c.Participants), 0);
	}

	get rates() {
		return this._activity.Rates.filter((r) => !r.IsPrivateTourRate && !r.InternalUseOnly);
	}
	get kioskRates() {
		return this._activity.Rates.filter((r) => !r.IsPrivateTourRate && r.ShowOnKiosk);
	}
	get selectedDate(): Date | null {
		return this._appointmentDate;
	}
	get selectedRates(): EntityHierarchyRate[] {
		return this.rates.filter((rate) => rate.Participants > 0);
	}
	get selectedKioskRates(): EntityHierarchyRate[] {
		return this.kioskRates.filter((rate) => rate.Participants > 0);
	}
	get selectedTimeKeys() {
		return Array.from(this._selectedTimes.keys());
	}
	get selectedAddons(): OrderEntityTimeAddOn[] {
		return this.selectedTimes.map((time) => time.AddOns).flat();
	}
	get selectedTimes() {
		return Array.from(this._selectedTimes.values());
	}
	get previousTimeString(): string | null {
		return this._lastTimeString;
	}

	get taxExempt() {
		return this._activity.TaxExempt;
	}

	set taxExempt(v: boolean) {
		this._activity.TaxExempt = v;
	}

	//#endregion
	//Just set the appointment date without triggering av

	setDate(d: Date): void {
		this._appointmentDate = startOfDay(d);
	}
	selectDate(d: Date): void {
		if (!this._appointmentDate || !isSameDay(d, this._appointmentDate!)) {
			this.resetAvailability();
			this._appointmentDate = startOfDay(d);
			// this.getTimes();
		}
	}

	toggleLargeGroup(): void {
		this._isLargeGroup = !this._isLargeGroup;
		this.resetAvailability();
		this._activity.Rates.forEach((r) => (r.Participants = 0));
	}

	resetAvailability(): void {
		this._appointmentDate = null;
		this._availableTimes.clear();
		// this._selectedTimes.clear();
		// this._monthAvailability.clear();
	}

	async prepareEdit(): Promise<void> {
		this.getTimes();
		this.getActivityOptions();
	}

	deselectTime(t: Time) {
		this._selectedTimes.delete(t.Value);
	}
	/**
	 * Gets times for activity on date
	 */
	async getTimes(): Promise<void> {
		if (this._appointmentDate) {
			this._isLoading = true;
			this.getTimesLoading = true;
			await apiService
				.post('GetTimes', {
					AddOns: this._activity.AddOns,
					Activity: this._activity,
					PhotoPackageAddOns: this._activity.PhotoPackageAddOns,
					IsPrivateTour: this._activity.IsPrivateTour ?? false,
					Date: format(this._appointmentDate, 'yyyy-MM-dd') + 'T00:00:00',
					ClientLocationKey: this.clientLocationKey,
					Partner_ClientLocationKey: clientModule.clientLocationKey,
					Rates: this.rates,
					IsLargeGroup: this.isLargeGroup,
					EntityHierarchyKey: this.entityHierarchyKey,
					PreviousPrivateTourState: false,
				})
				.then((res: Availability[]) => {
					console.log(res);
					if (res.length && Array.isArray(res[0].Times)) {
						res[0].Times.forEach((t: Time) => {
							if (this.isLargeGroup) t.Rates.forEach((r) => (r.Participants = 0)); // ensure no rates assigned - API BUG
							this._availableTimes.set(t.Value, t);
						});
					}
				})
				.catch((e) => console.error('[getTimes] Could not get times.', e))
				.finally(() => {
					this._isLoading = false;
					this.getTimesLoading = false;
				});
		}
	}
	async getBookTimes(): Promise<Availability | null> {
		let result: Availability | null = null;
		if (this._appointmentDate) {
			this._isLoading = true;
			this.getTimesLoading = true;
			await apiService
				.post('gettimes', {
					AddOns: this._activity.AddOns,
					Activity: this._activity,
					PhotoPackageAddOns: [],
					IsPrivateTour: this._activity.IsPrivateTour ?? false,
					Date: format(this._appointmentDate, 'yyyy-MM-dd') + 'T00:00:00',
					ClientLocationKey: this.clientLocationKey,
					Partner_ClientLocationKey: clientModule.clientLocationKey,
					Rates: this._activity.Rates,
					IsLargeGroup: this.isLargeGroup,
					EntityHierarchyKey: this.entityHierarchyKey,
					PreviousPrivateTourState: false,
				})
				.then((res: Availability[]) => {
					result = res[0];
					if (res.length && Array.isArray(res[0].Times)) {
						res[0].Times.forEach((t: Time) => {
							if (this.isLargeGroup) t.Rates.forEach((r) => (r.Participants = 0)); // ensure no rates assigned - API BUG
							this._availableTimes.set(t.Value, t);
						});
					}
				})
				.catch((e) => console.error('[getTimes] Could not get times.', e))
				.finally(() => {
					this._isLoading = false;
					this.getTimesLoading = false;
				});
		}
		return new Promise<Availability | null>((resolve) => {
			resolve(result);
		});
	}
	async checkTimes(): Promise<boolean> {
		let success = true;
		console.log('Calling GBT from checkTimes');
		await this.getBookTimes().then((res: Availability | null) => {
			console.log('Result:', res);
			if (res) {
				const times = this.selectedTimes.map((time) => time.Value);
				const allTimes = res.Times.map((time) => time.Value);
				console.log('order times', times);
				console.log('all times', allTimes);
				success = times.every((time) => allTimes.includes(time));
			} else {
				success = false;
			}
		});
		return new Promise<boolean>((resolve) => resolve(success));
	}
	/**
	 * select / deselect timeslot if it has participants
	 * @param t
	 */
	selectTime(t: Time): void {
		const count = t.Rates.reduce((p, c) => (p += c.Participants), 0);
		if (count) {
			if (!this.isLargeGroup) {
				//copy addons from last time and put them on the new time
				const addons = this.selectedTimes[0]?.AddOns;
				if (addons) t.AddOns = addons;
				this._selectedTimes.forEach((time) => (this._lastTimeString = time.AppointmentDate + ' @ ' + time.DisplayName));
				console.log('TIME>> ', this._lastTimeString);
				this._selectedTimes.clear();
			}
			this._selectedTimes.set(t.Value, t);
		} else this._selectedTimes.delete(t.Value); // remove if no participants
		console.log('setting time', t);
		// if (this._selectedTimes.size) this.getActivityOptions();
	}
	get pendingTime(): Time | null {
		return this._pendingTime;
	}
	set pendingTime(t: Time | null) {
		this._pendingTime = t;
	}
	selectPendingTime() {
		if (this.pendingTime) {
			this.selectTime(this.pendingTime);
			this.pendingTime = null;
		} else {
			console.warn('No pending time to select');
		}
	}
	/**
	 * Sets fields with updated activity data
	 * @param activity EntityHierarchy
	 */
	updateActivityOptions(activity: EntityHierarchy) {
		console.log('UPDATING ACTIVITY WITH', activity);
		this._activity = activity;
		if (activity.AppointmentDate) this._appointmentDate = new Date(activity.AppointmentDate);
		if (activity.SelectedTimes) activity.SelectedTimes.forEach((st) => this._selectedTimes.set(st.Value, st));
	}

	/**
	 * Gets calculated activity options
	 * (used mainly for selected times options)
	 */
	async getActivityOptions() {
		if (this.selectedTimes.length) {
			this._isLoading = true;
			await apiService
				.post('GetActivityOptions', [this.model])
				.then((res: EntityHierarchy[]) => {
					if (res.length) this.updateActivityOptions(res[0]);
					else console.error('GAO result is empty');
				})
				.catch((e) => console.error('Could not get activity options', e))
				.finally(() => (this._isLoading = false));
		}
	}

	async getMonthAvailability(d: Date): Promise<boolean> {
		this._isLoading = true;
		const payload = {
			IsPrivateTour: false,
			Date: format(d, 'yyyy-MM-dd'),
			EntityHierarchyKey: this.entityHierarchyKey,
			SpecificCulture: 'en-US',
			Rates: this.rates,
			ClientLocationKey: clientModule.clientLocationKey,
		};
		console.log(payload);
		return await apiService
			.post('GetMonthAvailability', payload)
			.then((res) => {
				if (Array.isArray(res)) {
					this._monthAvailability.set(format(d, 'MMMM-dd-yyyy'), res);
					return true;
				}
				return false;
			})
			.catch((e) => {
				console.error('Could not get month availability', e);
				return false;
			})
			.finally(() => (this._isLoading = false));
	}

	private buildRateString(rates: EntityHierarchyRate[]) {
		return rates
			.reduce((p: string[], c) => {
				if (c.Participants) p.push(`${c.Name} x ${c.Participants}`);
				return p;
			}, [])
			.join(', ');
	}
}
