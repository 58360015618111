import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';
import { EntityHierarchyRate } from '@/common/models/EntityHierarchyRate.model';
import { Item } from '@/common/models/Item.model';
import { Order } from '@/common/models/Order.model';
import { OrderEntityTimeRate } from '@/common/models/OrderEntityTime.model';
import { PackageModel } from '@/common/models/Package.model';
import { Product } from '@/common/models/Product.model';
import { RatesEntity } from '@/common/models/RateEntity.model';
import { clientModule } from '@/modules/client/client.vuex-module';
import { uuid } from '@/proxies/uuid.proxy';
import { apiService } from '@/services/api.service';
import store from '@/store';
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules';
import ActiveOrder from './common/classes/ActiveOrder.class';
import OrderActivity from './common/classes/OrderActivity.class';
import { Watch } from 'vue-property-decorator';
import { or } from '@vueuse/core';

export type RefundTypeAPI = 'RefundBalanceToGiftCard' | 'RefundToCardOnFile';
export type ModelError = {
	Value: [string];
};
@Module({ generateMutationSetters: true })
class OrderModule extends VuexModule {
	private _catalogView: PackageViewMode = PackageViewMode.INDIVIDUAL;
	private _openItem: Item | null = null;
	public _openActivity: OrderActivity | null = null;
	private _savedActivityKeys: Set<number> = new Set();
	private _itemActivities: Map<number, OrderActivity> = new Map();
	private _openProduct: Product | null = null;
	public _activeOrder: ActiveOrder | null = null;
	private _searchQuery = '';
	needsToAbandon = false;
	isEditing = false;
	isLoading = false;
	isCheckoutLoading = false;
	checkoutError: string | null = null;
	get allRatesSelected(): boolean {
		const allSelected: boolean = this.itemActivities.reduce((prev: boolean, a) => {
			return prev && a.rateCount > 0;
		}, true);
		return allSelected;
	}
	get allDatesSelected(): boolean {
		const allSelected: boolean = this.itemActivities.reduce((prev: boolean, a) => {
			return prev && !!a.selectedDate;
		}, true);
		return allSelected;
	}

	get allTimesSelected(): boolean {
		const allSelected: boolean = this.itemActivities.reduce((prev: boolean, a) => {
			return prev && a.selectedTimes.length > 0;
		}, true);
		return allSelected;
	}
	get allAddonsSelected(): boolean {
		const allSelected: boolean = this.itemActivities.reduce((prev: boolean, a) => {
			return prev && !!a.orderAddons.length;
		}, true);
		return allSelected;
	}
	get catalogView() {
		return this._catalogView;
	}
	get customFieldItems() {
		return this.items?.filter((i) => i.Activities.some((a) => a.CustomFields.length || a.SelectedTimes[0]?.Rates.some((r) => r.CustomFields.length))) ?? [];
	}
	get isLargeGroup() {
		return this._openItem?.IsLargeGroup ?? false;
	}

	get itemActivities() {
		return Array.from(this._itemActivities.values());
	}
	get selectedRates(): EntityHierarchyRate[] {
		return this.rates.filter((rate) => rate.Participants > 0);
	}
	get rates() {
		return !this.isLargeGroup ? this._openActivity?.rates ?? [] : [];
	}
	set rates(newValue) {
		if (this._openActivity) this._openActivity.activity.Rates = newValue;
	}
	get hasAddons(): boolean {
		return this.itemActivities.reduce((prev: boolean, item) => prev || !!item.activity.AddOns.length || !!item.activity.PhotoPackageAddOns.length, false);
	}
	get openActivity() {
		return this._openActivity;
	}

	get openItem() {
		return this._openItem;
	}

	// get activeOrder() {
	// 	return this._activeOrder;
	// }

	get readyToAddToCart() {
		return this.savedActivities.length == this.itemActivities.length;
	}

	get savedActivities() {
		return this.itemActivities.filter((a) => a.isSaved);
	}

	get savedActivityKeys() {
		return this._savedActivityKeys;
	}

	get searchQuery() {
		return this._searchQuery;
	}
	get items() {
		return this._activeOrder?.items;
	}

	constructor() {
		super({ store, name: 'orderModule' });
	}
	/**
	 * updates catalog view
	 * @param view
	 */
	@Action updateCatalogView(view: PackageViewMode) {
		this._catalogView = view;
	}

	/**
	 * sets currently viewed item
	 * @param i Item
	 */
	@Action setOpenItem(item: Item) {
		this.resetOpenItem();
		// console.log('*** Setting item to ', item);
		this._openItem = JSON.parse(JSON.stringify(item));
		this._openItem?.Activities.forEach((a: EntityHierarchy) => {
			const activity = new OrderActivity(a);
			this._itemActivities.set(activity.entityHierarchyKey, activity);
			console.log('Created activity', activity);
		});
		if (item.Activities[0]) this.setOpenActivity(item.Activities[0].EntityHierarchyKey);
	}
	@Action selectByOrderEntityTimeKey(key: string) {
		this.items?.forEach((item) => {
			item.Activities.forEach((activity) => {
				activity.SelectedTimes.forEach((time) => {
					if (time.OrderEntityTimeKey == key) {
						this.editItem(item);
						this.setOpenActivity(activity.EntityHierarchyKey);
					}
				});
			});
		});
	}

	@Action editItem(i: Item) {
		this.setOpenItem(i);
		this._savedActivityKeys.clear();
		i.Activities.forEach((a: EntityHierarchy) => {
			this._savedActivityKeys.add(a.EntityHierarchyKey); // mark as saved
		});
	}

	/**
	 * sets active/open activity
	 * @param k EntityHierarchyKey
	 */
	@Action setOpenActivity(k: number) {
		const orderActivity = this._itemActivities.get(k);
		// console.log('** Setting activity to ', orderActivity);
		if (orderActivity) this._openActivity = orderActivity;
	}

	/**
	 * Adds open activity to savedActivity set.
	 * If all activities have been saved calls savePackageToOrder() & closes open item/activity
	 */
	@Action async addActivityToCart() {
		if (this._openActivity) {
			this._savedActivityKeys.add(this._openActivity.entityHierarchyKey);
			if (!this.openItem!.PackageKey && this._savedActivityKeys.size == this._itemActivities.size) {
				// not a package and all activities completed
				return await this.saveToOrder().then((res) => {
					return res;
				});
			} else {
				return false;
			}
		}
		return false;
	}
	@Action async saveAll() {
		this._savedActivityKeys = new Set<number>(this.itemActivities.map((a) => a.entityHierarchyKey));
		return await this.saveToOrder();
	}
	async issueRefund(type: RefundTypeAPI, isCancellation: boolean | null, orderEntityTimeKey: string | null, orderPaymentKey: string | null): Promise<any> {
		const data = {
			OrderKey: this._activeOrder?.orderKey,
			ClientLocationKey: clientModule.clientLocationKey,
			OrderEntityTimeKey: orderEntityTimeKey,
			OrderPaymentKey: orderPaymentKey,
			IsCancellation: isCancellation,
			OrderPackageKey: this.openItem?.OrderPackageKey,
		};
		// return new Promise(() => {
		// 	console.log('Blocked!');
		// 	console.log(JSON.stringify(data));
		// });
		return await apiService.post(type, data).then((res) => {
			if (res && res.Order) {
				this.needsToAbandon = res.Order.Balance != 0;
				this._activeOrder = new ActiveOrder(res.Order);
				return res;
			} else {
				return res;
			}
		});
	}
	async cancelActivity(orderEntityTimeKey: string): Promise<any> {
		const payload = {
			OrderKey: this._activeOrder?.orderKey,
			OrderEntityTimeKey: orderEntityTimeKey,
			ClientLocationKey: clientModule.clientLocationKey,
		};
		console.log(payload);
		this.needsToAbandon = true;
		return await apiService
			.post('CancelActivity', payload)
			.then((res: any) => {
				this.isLoading = false;
				if (res.Order) {
					this.needsToAbandon = res.Order.Balance != 0;
					this._activeOrder = new ActiveOrder(res.Order);
				}
				return res;
			})
			.catch((e) => {
				this.isLoading = false;
				console.error(e);
				return null;
			});
	}
	@Action async saveToOrder(): Promise<ActiveOrder | [ModelError] | null> {
		console.log('Saving item to order...');
		if (this._openItem && this._savedActivityKeys.size) {
			this.isLoading = true;
			const payload: Partial<PackageModel> = {
				OrderKey: this._activeOrder?.orderKey,
				IsLargeGroup: this._openActivity?.isLargeGroup ?? false,
				ClientLocationKey: clientModule.clientLocationKey,
				Participants: this._openActivity?.rateCount,
				SkipOverlap: true,
				PasswordClientLoginKey: clientModule.clientPassword,
				PasscodeClientLoginKey: clientModule.user?.ClientLoginKey ?? '',
				OrderPackageKey: this.openItem?.OrderPackageKey,
				PackageKey: this._openItem.PackageKey,
				Activities: this.savedActivities.map((a) => a.model),
				Products: [],
				RecalculateTotals: true,
			};
			console.log(payload);
			this.needsToAbandon = true;
			return await apiService
				.post('SavePackageToOrder', payload)
				.then((res) => {
					console.log('SPTO res', res);
					this.isLoading = false;
					if (res.Order) {
						this.needsToAbandon = res.Order.Balance != 0;
						this._activeOrder = new ActiveOrder(res.Order);
						return this._activeOrder;
					} else if (res.ModelErrors && res.ModelErrors.length) {
						return res.ModelErrors;
					} else {
						console.error('[SaveToOrder] ERROR', res);
						return null;
					}
				})
				.catch((e) => {
					this.isLoading = false;
					console.error(e);
					return null;
				});
		} else {
			console.log('Error', this._openItem, this._savedActivityKeys);
		}
		return null;
	}
	@Action async getOrder(orderKey: string) {
		console.log(`Fetching order ${orderKey}...`);
		return await apiService
			.get('GetOrder', {
				orderKey: orderKey,
				clientLocationKey: clientModule.clientLocationKey,
			})
			.then((order: Order) => {
				if (order && order.OrderKey) {
					this._activeOrder = new ActiveOrder(order);
					return true;
				}
				return false;
			})
			.catch((e) => {
				console.error('Could not fetch order', e);
				return false;
			});
	}
	@Action async removeFromOrder(keys: { entityKey?: string; orderPackageKey?: string }) {
		await apiService
			.get('register/RemovePackageFromOrder', {
				orderEntityKey: keys.entityKey ?? null,
				orderKey: this._activeOrder?.orderKey,
				clientLocationKey: clientModule.clientLocationKey,
				orderPackageKey: keys.orderPackageKey ?? null,
				orderEntityTimeKey: null,
			})
			.then((res) => {
				if (!res.ModelErrors && res.Order) {
					this._activeOrder = new ActiveOrder(res.Order);

					if (!this._activeOrder.items.length) this.abandonCheckout();
				}
			});
	}

	@Action async continueCheckout() {
		if (this._activeOrder) {
			this.isCheckoutLoading = true;
			return await apiService
				.post('checkout', {
					clientLocationKey: clientModule.clientLocationKey,
					orderKey: this._activeOrder?.orderKey,
					// passwordClientLoginKey: clientModule.clientPassword,
					// passcodeClientLoginKey: clientModule.user?.ClientLoginKey ?? null,
					requiresPasscodeToCheckout: false,
					giftCardVariantChanged: false,
					products: [],
				})
				.then((res: { ModelErrors: string[]; Order: Order }) => {
					if (!res) {
						this.checkoutError = 'Error processing checkout';
						return;
					}
					this.isCheckoutLoading = false;
					if (!res.ModelErrors && res.Order) {
						this._activeOrder = new ActiveOrder(res.Order);
					} else {
						this.checkoutError = res.ModelErrors?.join(', ') ?? '';
					}
				})
				.catch((e) => {
					console.log(e);
					this.checkoutError = e;
					this.isCheckoutLoading = false;
				});
		}
		return;
	}
	@Action async abandonOrderChanges() {
		this.needsToAbandon = false;
		if (this._activeOrder) {
			const ao = this._activeOrder;
			this._activeOrder = null;
			ao?.abandonOrderChanges().then((res: Order) => {
				this._activeOrder = new ActiveOrder(res);
			});
		}
	}
	@Action async abandonCheckout() {
		if (this._activeOrder) {
			console.log(`Abandoning order ${this._activeOrder.orderKey}`);
			apiService.post('AbandonCheckout', {
				clientLocationKey: clientModule.clientLocationKey,
				orderKey: this._activeOrder.orderKey,
				orderPackageKeys: this._activeOrder.orderPackageKeys,
				orderEntityKeys: this._activeOrder.orderEntityKeys,
				orderMerchandiseKeys: [],
			});
			this.resetActiveOrder();
		}
	}

	@Action resetOpenItem() {
		console.log('RESETTING OPEN ITEM!');
		this._openItem = null;
		this._openActivity = null;
		this._itemActivities.clear();
		this._savedActivityKeys.clear();
	}

	@Action resetAvailability() {
		this._openActivity?.resetAvailability();
	}

	@Action resetOpenActivity() {
		this._openActivity = null;
	}

	@Action resetActiveOrder() {
		this._activeOrder = null;
		this._openActivity = null;
		this._openItem = null;
		this._savedActivityKeys.clear();
		this._itemActivities.clear();
	}

	/**
	 * updates search query filter
	 * @param s
	 */
	@Action updateSearchQuery(s: string) {
		this._searchQuery = s;
	}
}

export const orderModule = new OrderModule();
